.ui.container {
    margin-top: 1em;
    margin-bottom: 2em;
}

span {
    text-align: center;
}

.ui.secondary.menu {
    margin: 0;
}

.logo {
    margin: 0 auto;
    width: 190px;
    height: auto;
}

/* general headlines */
h1.ui.header {
    color: #865A48;
}

h2.ui.header, h3.ui.header {
    color: #252A34;
    text-align: center;
}

h2.ui.header {
    margin-top: 0;
}

h3.ui.header {
    margin-bottom: 32px;
}

/* week navigation */
.ui.top.attached.week_nav.menu {
    margin-bottom: 32px;
}


/* game list item */
.game_item {
    display: flex;
    margin-bottom: 16px;
}

.game_item div {
    display: flex;
    flex-direction: row;
}

.game_item .ui.attached.segment{
    background: #e0edd6;
    border-radius: 16px;
}

.game_item .ui.attached.segment .column {
    flex: 1 1 auto;
}

.ui.fluid.image.teamLogo {
    min-width: 90px;
}


/* ########### DIVIDER STUFF */
/* tag groups */

.ui.vertical.divider::after, .ui.vertical.divider::before {
    border: 0
}

.ui.vertical.divider {
    top: 30%;
    line-height: 32px;
    color: white;
    flex-direction: column;
}

.ui.tagSegment {
    background: none !important;
}

/* voting buttons */
.voting_buttons {
    background-color: #557153;
}

.voting_buttons span {
    align-self: center;
}

.ui.button:hover, .ui.active.button:active, .ui.button:active, .ui.button:focus {
    background-color: #5F8D4E;
    color: white;
}

.ui.button {
    background-color: #557153;
    color: white;
    /* min-width: 11rem; */
    max-height: 36px;
    font-size: 1.4rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
}


.ui.tag.labels.tagGroup {
    flex-direction: column;
    margin-top: 16px;
}

.ui.label.tagColor {
    background-color: #865A48;
    color: white
}

.ui.label.tagColor:hover {
    background-color: #865A48;
    color: white;
    cursor: default;
}

.ui.basic.segment.footer > * {
    padding: 0px 0.5rem;
}


/* ### MOBILE */
@media (max-width: 767px) {
    .ui.grid .stackable.row .ui.vertical.divider, .ui.stackable.grid .ui.vertical.divider {
       margin-top: 32px;
    }

    .ui.tag.labels.tagGroup {
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .game_item .ui.attached.segment .column {
        padding: 0rem 0rem !important
    }

    .ui.fluid.image.teamLogo {
        min-width: 60px;
    }
}

/* Verstecke die horizontale Scrollbar, aber ermögliche das Scrollen */
    